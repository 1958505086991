// COMPONENTS
import React from 'react'
import FullHeightPage from 'templates/FullHeightPage'
import ProfileCard from 'components/ProfileCard'
// CONTENT
import content from 'content/card.yml'

// Contact card page
const ContactCardPage = () => {
  const { card } = content
  return (
    <FullHeightPage content={content}>
      {card && <ProfileCard content={card} />}
    </FullHeightPage>
  )
}

export default ContactCardPage
