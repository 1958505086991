// COMPONENTS
import React, { useState } from 'react'
import Section from 'layout/Section'
// BOOTSTRAP
import Spinner from 'layout/Spinner'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
// HOOKS
import { useExternalContent } from '../hooks/useExternalContent.js'
// LOGO
import Icon from 'elements/Icon'

// ProfileCard component
const ProfileCard = ({ content }) => {
  const [ showCode, setShowCode ] = useState(false)
  const cont = useExternalContent( content )
  if (cont) {
    const { section, firstName, lastName, title, company, specialties, contacts, logoUrl, bgVideo, qrCode } = ( cont.isExtContent ) ? cont.card : cont
    return (
    <Section content={section} bgVideo={bgVideo} isSPA={true}>
      <Container fluid>
        <Row className='align-items-center p-3'>
          <Col id='name' className='mb-4i text-left' md={6} xs={12}>
            { firstName && <h1 className='display-1'>{firstName}</h1> }
            { lastName && <h1 className='display-2'>{lastName}</h1> }
            { title && <h5 className='display-4'>{title}</h5> }
            { company && <h5 className='display-4'>{company}</h5> }
            { specialties && <h6 className='display-5'>{specialties}</h6> }
          </Col>
          { logoUrl &&
            <Col id='logo' className='align-self-center px-0 py-5' md={6} xs={12}>
              <div style={{width: '100%'}}>
                <Button className='bg-transparent btn-link shadow-none' style={{border:'0px solid transparent'}} onClick={()=>setShowCode(!showCode)} onKeyDown={()=>null} >
                  <img src={logoUrl} alt='logo' style={{maxWidth: '100%'}} /> 
                </Button>
              </div>
            </Col>
          }
          { qrCode && showCode &&
            <Col id='qrcode' className='align-self-center px-0 py-5' md={6} xs={12}>
              <div style={{width: '100%'}}>
                <img className='rounded' src={qrCode} alt='qrcode' style={{width: '100%'}}/> 
              </div>
            </Col>
          }
        { contacts && 
          <Col id='contacts' className='d-flex justify-content-center' md={6} xs={12} >
            {contacts.map(contact => (
              <Col className='' key={contact.title} > 
                <Col className='d-flex justify-content-center'>
                  <a href={contact.url} className='text-info' target='_blank' rel='noreferrer'>
                    {contact.icon && <Icon name={contact.icon} /> }
                  </a>
                </Col>
                <Col className='d-flex justify-content-center'>
                  <a href={contact.url} target='_blank' rel='noreferrer'>
                    <h6 className='text-light'>{contact.title}</h6>
                  </a>
                </Col>
              </Col>
            ))}
          </Col>
        }
        </Row>
      </Container>
    </Section>
    )
  } else {
    return(
      <Container className='py-5' fluid>
        <Spinner name='PuffLoader' size='100px' />
      </Container>
    )
  }
}

export default ProfileCard
